.carousel-container {
  margin: 0 auto;
}

.slide-text {
  width: 430px;
}

.horizontal-slider {
  width: 816px !important;
  height: 365px !important;
}

.inner-slide {
  align-items: center;
  gap: 32px;
}

.slide-with-text {
  display: flex;
  gap: 32px;
  border-radius: unset !important;
  background: transparent !important;
  height: 365px !important;
}

.slide-with-text .image {
  box-sizing: border-box;
  width: 354px;
  height: 365px;
  border-radius: 12px;
  background: #F8F5FF;
  display: flex;

  &>* {
    margin: auto;
    width: auto;
    max-width: 314px;
    max-height: 301px;
    font-size: 48px;
    text-wrap: wrap;
    text-align: center;
  }
}

.carousel {
  width: 927px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;

  &>div:first {
    width: 927px;
  }
}

.carousel-container {}

@media screen and (max-width: 540px) {
  .carousel-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}