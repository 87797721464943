.card {
  width: 288px;
  min-height: 269px;
  padding: 32px 18px;
  font-weight: 300;
  line-height: 130%;

  b {
    width: 212px;
  }

  pre {
    margin-top: 64px;
  }
}

.carousel-and-text {
  display: flex;
  gap: 32px;
  align-items: center;
  margin-top: 32px;
}

.carousel-text-container {
  max-width: 448px;
  width: 90%;
}

@media screen and (max-width: 979px) {
  .carousel-and-text {
    flex-direction: column;
  }
}

@media screen and (max-width: 527px) {
  .subtitle {
    display: none;
  }

  .carousel-container > div {
    width: 90%;
  }
}

@media screen and (max-width: 400px) {
  .title {
    font-size: 46px;
  }
}