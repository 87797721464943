.container {
  color: #4A4A4A;
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;

  margin-top: 24px;
  margin-bottom: 0;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 20px;
}